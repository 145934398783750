import React, { useContext } from "react"
import PropTypes from "prop-types"

// Context
import NavBarItemContext from "../../context/navBarItemContext"

const ItemIconMobile = ({ url, socialClass }) => {
  const { setShowMenuNavMobile } = useContext(NavBarItemContext)

  const handleClick = () => {
    setShowMenuNavMobile(false)
  }

  return (
    <a className="options-social-chpa" href={url} rel="noreferrer noopener" target="_blank" onClick={handleClick}>
      <i className={`fa ${socialClass}`}></i>
    </a>
  )
}

ItemIconMobile.prototypes = {
  url: PropTypes.string.isRequired,
  socialClass: PropTypes.string.isRequired,
}

export default ItemIconMobile
