export default [
  {
    origin: "SD",
    destination: "SD",
    name: "SDSD",
    nameText: "Origin: SD | Destination: SD",
  },
  {
    origin: "SD",
    destination: "CY",
    name: "SDCY",
    nameText: "Origin: SD | Destination: CY",
  },
  {
    origin: "CY",
    destination: "SD",
    name: "CYSD",
    nameText: "Origin: CY | Destination: SD",
  },
  {
    origin: "CY",
    destination: "CY",
    name: "CYCY",
    nameText: "Origin: CY | Destination: CY",
  },
]
