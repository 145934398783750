export default [
  "Adhesive tape, plastic",
  "Aircraft, spacecraft, and parts thereof",
  "Albacore, frozen, fish",
  "Albacore, longfinned , frozen, fish",
  "Albacore, longfinned , non-frozen, fish",
  "Albacore, non-frozen, fish",
  "Alcoholic beverages, nos",
  "All",
  "Aluminium, aluminium articles, metal",
  "Animal fats, animal oils, lards",
  "Animal fodder, pet food, frozen",
  "Animal fodder, pet food, non-frozen",
  "Apple concentrate, apple juice, frozen, foodstuff",
  "Apple concentrate, apple juice, non-frozen, foodstuff",
  "Apples, frozen, fruit",
  "Apples, non-frozen, fruit",
  "Appliances and kitchenware",
  "Apricot, frozen, fruit",
  "Apricot, non-frozen, fruit",
  "Art, antiques, collectors pieces",
  "Art, antiques, collectors pieces, Bric-a-Brac",
  "Artichokes, frozen, vegetables",
  "Artichokes, non-frozen, vegetables",
  "Artificial flowers",
  "Asparagus, frozen, vegetables",
  "Asparagus, non-frozen, vegetables",
  "Aubergine, eggplant, frozen, vegetables",
  "Aubergine, eggplant, non-frozen, vegetables",
  "Autoparts, car parts, vehicle parts, motorcycle parts, bicycle parts, new",
  "Autoparts, car parts, vehicle parts, motorcycle parts, bicycle parts, used",
  "Avocado, frozen, fruit",
  "Avocado, non-frozen, fruit",
  "Bairdi crab, frozen, shellfish",
  "Bairdi crab, non-frozen, shellfish",
  "Banana, plantains, frozen, fruit",
  "Banana, plantains, non-frozen, fruit",
  "Base metals, base metal articles",
  "Batteries",
  "Beans, frozen, vegetables",
  "Beans, non-frozen, vegetables",
  "Beef, meat, frozen",
  "Beef, meat, non-frozen",
  "Beer, beverages",
  "Beetroot, red beet, celeriac, radishes, frozen, vegetables",
  "Beetroot, red beet, celeriac, radishes, non-frozen, vegetables",
  "Berries nos, frozen",
  "Berries nos, non-frozen",
  "Beverages",
  "Beverages, softdrink",
  "Bitter gourd, cherimoya, chillies, courgette, zucchini, fennels, sugar peas, scorzoner, taro, malang",
  "Bi-valve mollusks, live, shellfish",
  "Black cod, frozen, fish",
  "Black cod, non-frozen, fish",
  "Black fermented tea in packings <= 3 kg, frozen",
  "Black fermented tea in packings <= 3 kg, non-frozen",
  "Black fermented tea in packings > 3 kg, non-frozen",
  "Blueberries, raspberries, frozen, berries",
  "Blueberries, raspberries, non-frozen, berries",
  "Bone and meal",
  "Bone Meal, non frozen",
  "Books, newspapers, pictures, printed matter",
  "Bottom fish, nos, frozen, fish",
  "Bottom fish, nos, non-frozen, fish",
  "Bovine Semen, frozen",
  "Broccoli, cauliflower, frozen, vegetables",
  "Broccoli, cauliflower, non-frozen, vegetables",
  "Brussels sprouts, frozen, vegetables",
  "Brussels sprouts, non-frozen, vegetables",
  "Bulk liquids - FROZEN",
  "Bulk liquids, non-frozen",
  "Butter, fats, oils, frozen",
  "Butter, fats, oils, non-frozen",
  "Cabbage, non-frozen, vegetables",
  "Calcium hypochlorite Dangerous cargo",
  "Calcium Products NOT Calcium Hypochlorite",
  "Candles, tapers",
  "Carambolas, starfruits, durians, cha-nee, feijoas, jackfruit, kumquats, whortleberries, persimmon, k",
  "Carbon Black",
  "Carpets, floor coverings, textiles",
  "Carrots, turnips, frozen, vegetables",
  "Carrots, turnips, non-frozen, vegetables",
  "Cars knocked down",
  "Celery, frozen, vegetables",
  "Celery, non-frozen, vegetables",
  "Ceramics, stoneware",
  "Cereals, pop corn, wheat, corn, rye, oats, corn, rice",
  "Cheese, fresh, grated or powdered, non-frozen",
  "Cheese, frozen",
  "Chemical products, nos",
  "Chemicals",
  "Cherries, frozen, berries",
  "Cherries, non-frozen, berries",
  "Chestnuts",
  "Chicory, non-frozen, vegetables",
  "Chocolate, frozen, foodstuff",
  "Chocolate, non-frozen, foodstuff",
  "Cigarettes",
  "Citrus various, orange, frozen, fruit",
  "Citrus various, orange, non-frozen, fruit",
  "Clay",
  "Clementine, mandarins, tangerines, frozen, fruit",
  "Clementine, mandarins, tangerines, non-frozen, fruit",
  "Clethodim",
  "Clocks, watches and parts thereof",
  "Cocoa beans, foodstuff",
  "Cocoa butter, foodstuff",
  "Cocoa powder, foodstuff",
  "Coconuts, brazil nuts, cashew nuts",
  "Cod fish, frozen, fish",
  "Cod fish, non-frozen, fish",
  "Cod milt, pollock milt, frozen, fish",
  "Cod milt, pollock milt, non-frozen, fish",
  "Cod roe, frozen, fish",
  "Cod roe, non-frozen, fish",
  "Cod stomachs, pollock stomachs, frozen, fish",
  "Cod stomachs, pollock stomachs, non-frozen, fish",
  "Coffee extracts, frozen",
  "Coffee extracts, non-frozen",
  "Coffee, non-roasted, frozen",
  "Coffee, non-roasted, non-frozen",
  "Coffee, roasted , non-frozen",
  "Coffee, roasted, frozen",
  "Computers, computer parts, new",
  "Computers, computer parts, used",
  "Confectionery, candy, foodstuff",
  "Consolidated Cargo",
  "Construction and building material, insulation material, metal",
  "Copper, copper articles, metal",
  "Copper, refined, metal",
  "Cork, articles of cork",
  "Corn, baby, frozen, vegetables",
  "Corn, baby, non-frozen, vegetables",
  "Cotton, raw, in bales",
  "Cotton, textiles",
  "Crab pots, frozen, shellfish",
  "Crab pots, non-frozen, shellfish",
  "Crab waste, frozen, shellfish",
  "Crab waste, non-frozen, shellfish",
  "Crab, canned, preserved, frozen, shellfish",
  "Crab, canned, preserved, non-frozen, shellfish",
  "Crab, nos, frozen, shellfish",
  "Crab, nos, non-frozen, shellfish",
  "Cranberries, frozen, berries",
  "Cranberries, non-frozen, berries",
  "Crustaceans, live, shellfish",
  "Cucumber, frozen, vegetables",
  "Cucumber, non-frozen, vegetables",
  "Cut flowers",
  "Cuttle fish, frozen, shellfish",
  "Cuttle fish, non-frozen, shellfish",
  "Cyanide, cyanide products",
  "Daimler Car - 1",
  "Daimler Car - 2",
  "Daimler Car - 3",
  "Dairy products",
  "Dairy products, frozen, nos",
  "Dairy products, non-frozen, nos",
  "Dates, frozen, fruit",
  "Dates, non-frozen, fruit",
  "Detergents",
  "Diplomatic Cargo",
  "Diplomatic Cargo_Non_US Flag",
  "Diplomatic Cargo_US Flag",
  "Eel, frozen, fish",
  "Eel, non-frozen, fish",
  "Eggs all kind, frozen",
  "Eggs all kind, non-frozen",
  "Electronics, electronic appliances, audio, video equipment, telecommunication equipment, new",
  "Electronics, electronic appliances, audio, video equipment, telecommunication equipment, used",
  "Empty tanks, containers, metal",
  "Endive, escarole, frozen, vegetables",
  "Endive, escarole, non-frozen, vegetables",
  "Enzymes ",
  "Epoxy Resin, plastic",
  "Esparto, other plaiting materials , and articles of esparto, other plaiting materials",
  "Essential oils",
  "Explosives, pyrotechnic products, nos",
  "Fabrics, nos, textiles",
  "FAK",
  "Fertilizers",
  "Figs, frozen, fruit",
  "Figs, non-frozen, fruit",
  "Firearms and similar devices which operate by the firing of an explosive charge, e.g. sporting shotg",
  "Fireworks",
  "Fish bones, unprocessed",
  "Fish fats, fish oils",
  "Fish fillet, dried, salted",
  "Fish fillets, frozen",
  "Fish fillets, non-frozen",
  "Fish meal",
  "Fish others, ULT(-60C/-76F)",
  "Fish roe, frozen",
  "Fish roe, non-frozen",
  "Fish, dried",
  "Fish, nos, frozen",
  "Fish, nos, non-frozen",
  "Flour all kinds",
  "Flower bulbs",
  "FMC EXEMPT - Logs, lumber",
  "FMC EXEMPT - Paperboard, KLB, Kraft Liner board, linerboard, newsprint, mail",
  "FMC EXEMPT - Pulping and papermaking derivatives, granular or liquid",
  "FMC EXEMPT - Veneer, sliced, peeled",
  "FMC EXEMPT - Wood pulp",
  "FMC EXEMPT -Timber, sawn",
  "Foliage, grass, moss",
  "Food Culture, ULT(-60C/-76F), foodstuff",
  "Food preparations, foodstuff",
  "Foodstuff",
  "Foodstuff, nos, non-frozen",
  "Footwear, new, apparel",
  "Footwear, used, apparel",
  "French fries, IQF, frozen, vegetables",
  "French fries, IQF, non-frozen, vegetables",
  "Fresh or chilled truffles, vegetables",
  "Frogs legs, frozen",
  "Frogs legs, non-frozen",
  "Frozen Chemical Products, Nos",
  "Frozen furskins, artificial fur",
  "Fruit and nuts",
  "Fruits, nuts, dried, nos, non-frozen",
  "Fruits, nuts, nos, frozen",
  "Fuel wood, charcoal",
  "Furniture",
  "Furniture, nos",
  "Furskins, artificial fur",
  "Garlic dried, frozen, vegetables",
  "Garlic dried, non-frozen, vegetables",
  "Garments, apparel, new",
  "Garments, apparel, used",
  "Ginger",
  "Glass, glassware",
  "Gloves, surgical gloves, rubber",
  "Gooseberries, pineapple cherries, frozen, berries",
  "Gooseberries, pineapple cherries, non-frozen, berries",
  "Grapefruit, frozen, fruit",
  "Grapefruit, non-frozen, fruit",
  "Grapes, frozen, fruit",
  "Grapes, non-frozen, fruit",
  "Guavas, mangoes, mangoes, guavas, mangosteen, frozen, fruit",
  "Guavas, mangoes, mangoes, guavas, mangosteen, non-frozen, fruit",
  "Halibut, frozen, fish",
  "Handbags, leather, trunks, suitcase, briefcase, wallets, purces, back-packs, school bags",
  "Handtools",
  "Hats, caps, new, apparel",
  "Hats, caps, used, apparel",
  "Hay, Straw and articles of straw",
  "Helium, liquid or gas",
  "Herring roe, frozen, fish",
  "Herring roe, non-frozen, fish",
  "Herring, frozen, fish",
  "Herring, non-frozen, fish",
  "Hides, fur, leather and skin",
  "Honey, frozen",
  "Honey, non-frozen",
  "Horse Mackerel",
  "Household articles of iron and steel, metal",
  "Household goods",
  "Household goods, personal effects, not for resale or public distribution",
  "Hydrazine",
  "Ice cream, frozen, foodstuff",
  "Impregnated textile fabrics",
  "Industrial Citrus",
  "Industrial Vegetables",
  "Inorganic chemicals, nos",
  "Iron ore",
  "Iron, steel, iron and steel articles, metal",
  "Jam, jelly, marmalade, frozen, foodstuff",
  "Jam, jelly, marmalade, non-frozen, foodstuff",
  "Juice, concentrate, nos, frozen, foodstuff",
  "Juice, concentrate, nos, non-frozen, foodstuff",
  "King crab, frozen, shellfish",
  "King crab, non-frozen, shellfish",
  "Kitchen and restaurant utilities, appliance, cookware,  cutlery, flatware, dinner ware, china, dishe",
  "Kiwi, frozen, fruit",
  "Kiwi, non-frozen, fruit",
  "Lactose, gums, foodstuff",
  "Lamb, sheep, frozen, meat",
  "Lamb, sheep, non-frozen, meat",
  "Lamps, lighting fixtures",
  "Lead, lead articles, metal",
  "Leather accessories",
  "Leeks, frozen, vegetables",
  "Leeks, non-frozen, vegetables",
  "Lemons, lime, non-frozen, fruit",
  "Lettuce, non-frozen, vegetables",
  "Live fish",
  "Lobster, frozen, shellfish",
  "Lobster, non-frozen, shellfish",
  "Logs, lumber",
  "Lubricants",
  "Machinery or mechanical appliances, new",
  "Machinery or mechanical appliances, used",
  "Mackerel",
  "Malt",
  "Manioc, cassava, yukka, frozen, vegetables",
  "Manioc, cassava, yukka, non-frozen, vegetables",
  "Man-made filaments, textiles",
  "Man-made staple fibres, textiles",
  "Margarine, frozen",
  "Margarine, non-frozen",
  "Matches",
  "Mate",
  "Meat, nos, frozen",
  "Meat, nos, non-frozen",
  "Melons cantaloupe, honey, net, frozen, fruit",
  "Melons cantaloupe, honey, net, non-frozen, fruit",
  "Metal",
  "Milk, cream, yogurt, frozen",
  "Milk, cream, yogurt, non-frozen",
  "Mineral fuels, oils, waxes",
  "Minerals",
  "Miscellaneous manufactured materials",
  "Molybdenum wire, metal",
  "Motorized vehicles, cars, buses, trucks, lorries, motorcycles, minivan, set-up, new",
  "Mushrooms, frozen, vegetables",
  "Mushrooms, non-frozen, vegetables",
  "Musical instruments",
  "Natural or cultured pearls, precious or semi-precious stones, precious metals, metals clad with prec",
  "New - TIRES, TYRES, RUBBER",
  "Nickel, nickel articles, metal",
  "Non-US Flag - US Agricultural Cargoes",
  "Non-US Flag - US Civilian Agencies Cargo",
  "Non-US Flag - US Foreign Military Sales",
  "Octopus, frozen, shellfish",
  "Octopus, non-frozen, shellfish",
  "Oils and fats",
  "Onion, frozen, starvent, vegetables",
  "Onion, non-frozen, starvent, vegetables",
  "Onions, frozen, vegetables",
  "Onions, non-frozen, vegetables",
  "Opilio crab, frozen, shellfish",
  "Opilio crab, non-frozen, shellfish",
  "Optical, photographic, cinematographic, measuring, checking, precision, medical or surgical instrume",
  "Orange juice, frozen, foodstuff",
  "Orange juice, non-frozen, foodstuff",
  "Ores, nos",
  "Ores, slag and ash",
  "Organic chemicals, nos",
  "Other vegetable textile fibres, paper yarn, woven fabrics of paper yarn",
  "Palm oil",
  "Papaya, frozen, fruit",
  "Papaya, non-frozen, fruit",
  "Paper",
  "Paper, paperboard, packing material",
  "Paperboard, KLB, Kraft Liner board, linerboard, newsprint, mail",
  "Pastry, bread, cake, frozen, foodstuff",
  "Pastry, bread, cake, non-frozen, foodstuff",
  "Patio furniture",
  "Peaches, nectarines, frozen, fruit",
  "Peaches, nectarines, non-frozen, fruit",
  "Peanuts",
  "Pears, quinces, frozen, fruit",
  "Pears, quinces, non-frozen, fruit",
  "Peas, frozen, vegetables",
  "Peas, non-frozen, vegetables",
  "Peel of melon or citrus, frozen",
  "Peel of melon or citrus, non-frozen",
  "Peppers capsicum, frozen, vegetables",
  "Peppers capsicum, non-frozen, vegetables",
  "Perfumes, make-up, cosmetics, toilet preparations",
  "Personal care and cleaning products",
  "Pharmaceutical products, medicaments, low value less than USD 500,000",
  "Pharmaceuticals",
  "pharmaceuticals products, medicaments, > 3,000,000 USD",
  "pharmaceuticals products, medicaments, > 500,000 and < 3,000,000 USD and Blood Plasma",
  "Phosphates",
  "Photo conductor",
  "Photographic paper, photographic film",
  "Pineapple juice, frozen, foodstuff",
  "Pineapple juice, non-frozen, foodstuff",
  "Pineapples, frozen, fruit",
  "Pineapples, non-frozen, fruit",
  "Plants",
  "Plants, potted",
  "Plastic and rubber",
  "Plastic, plastic articles, new",
  "Plastic, plastic articles, used",
  "Plates, sheets, film, foil, plastic",
  "Plums, sloes, non-frozen, fruit",
  "Plywood, panel, board products",
  "Polishes, creams",
  "Pollock fish, frozen, fish",
  "Pollock fish, non-frozen, fish",
  "Pollock roe, frozen, fish",
  "Pomegranates, frozen",
  "Pomegranates, non-frozen",
  "Pork, swine, frozen, meat",
  "Pork, swine, non-frozen, meat",
  "Potatoes, frozen, vegetables",
  "Potatoes, non-frozen, vegetables",
  "Poultry, chicken, turkey, duck, fowl, frozen",
  "Poultry, chicken, turkey, duck, fowl, non-frozen",
  "Prepared feathers and down and articles made of feathers or of down, artificial flowers, articles of",
  "Prepared feathers, down, articles made of feathers or of down, artificial flowers, articles of human",
  "Project Cargo_Non_US Flag",
  "Project Cargo_US Flag",
  "Project exported in accordance with commission regulation (ec) no 840/96",
  "Provisions, food additives, vitamins, foodstuff",
  "Pulping and papermaking derivatives, granular or liquid",
  "Rags, textile waste",
  "Railway equipment",
  "Raw hides, skins, dry",
  "Raw hides, skins, wet",
  "Raw hides, skins, wet, frozen",
  "Refuse derived fuel",
  "Relief goods",
  "Rubber articles, rubber goods",
  "Sable fish, frozen, fish",
  "Sable fish, non-frozen, fish",
  "Sacks and bags, plastic",
  "Salmon roe, frozen, fish",
  "Salmon roe, non-frozen, fish",
  "Salmon, frozen, fish",
  "Salmon, non-frozen, fish",
  "Salmon, pink chum, frozen, fish",
  "Salmon/Trout, ULT(-60C/-76F), fish",
  "Salt, sulphur, earths and stone, plastering materials, lime and cement",
  "Salt, sulphur, earths and stone, plastering materials, lime, cement, marble, granite",
  "Sardines, frozen, fish",
  "Sardines, non-frozen, fish",
  "Sauce, frozen, foodstuff",
  "Sauce, non-frozen, foodstuff",
  "Scrap metal",
  "Sea Urchin, ULT(-60C/-76F), shellfish",
  "Sea urchins, frozen, shellfish",
  "Sea urchins, non-frozen, shellfish",
  "Seats, convertible into beds",
  "Seaweed",
  "Seaweed ash",
  "Seed cake",
  "Seed meal",
  "Seeds, beans, cereals and flour",
  "Seeds, grains, industrial plants, medicinal plants",
  "Seeds, sesame seeds",
  "Shampoo",
  "Shellfish,  IQF, nos, frozen",
  "Shellfish,  IQF, nos, non-frozen",
  "Shells, crab, crushed",
  "Ships, boats and floating structures",
  "Shotguns - sports goods",
  "Shrimps, prawns, frozen, shellfish",
  "Shrimps, prawns, non-frozen, shellfish",
  "Silk, textiles",
  "Skipjack, frozen, fish",
  "Skipjack, non-frozen, fish",
  "Slag, ash",
  "Smoked fish, frozen",
  "Smoked fish, non-frozen",
  "Snails, frozen, shellfish",
  "Snails, non-frozen, shellfish",
  "Soap",
  "Solid Recovered fuel",
  "Soups, broth, frozen, foodstuff",
  "Soups, broth, non-frozen, foodstuff",
  "Soy, soybeans",
  "Spices",
  "Spinach, frozen, vegetables",
  "Spinach, non-frozen, vegetables",
  "Sports goods",
  "Spring Onion",
  "SRF pellets",
  "Starches, glue",
  "Steel coil",
  "Straw, esparto, other plaiting materials and articles of straw, esparto, other plaiting materials",
  "Strawberries, frozen, berries",
  "Strawberries, non-frozen, berries",
  "Sugar, syrup, foodstuff",
  "Surimi, frozen, fish",
  "Swedes, non-frozen, vegetables",
  "Sweet potatoes, frozen, vegetables",
  "Sweet potatoes, non-frozen, vegetables",
  "Sweetcorn, frozen, vegetables",
  "Sweetcorn, non-frozen, vegetables",
  "Synthetic resins, plastic",
  "Tanning extracts, dyeing extracts,  paints, varnishes",
  "Tea and coffee",
  "Tea, bags, frozen",
  "Tea, bags, non-frozen",
  "Tea, loose, non-frozen",
  "Textiles and apparel",
  "Textiles, cotton piece goods",
  "Tile, stone and glass",
  "Tile, stone articles",
  "Timber, sawn",
  "Tin, tin articles, metal",
  "Titanium tetrachloride",
  "Tobacco",
  "Tobacco, tobacco accessories",
  "Toilet paper, paper napkins, diapers, sanitary towels",
  "Tomatoes, frozen, vegetables",
  "Tomatoes, non-frozen, vegetables",
  "Toys and games",
  "Toys, games",
  "Trichloric acid",
  "Tuna, Albacore, ULT(-60C/-76F), fish",
  "Tuna, Land Processed, ULT(-60C/-76F), fish",
  "Tuna, NOE, ULT(-60C/-76F), fish",
  "Tuna, Tuna, ULT(-60C/-76F), fish",
  "Tuna, Yellow Fin, ULT(-60C/-76F), fish",
  "Tuna, yellowfin, frozen, fish",
  "Tuna, yellowfin, non-frozen, fish",
  "Tungsten wire, metal",
  "Twine, cordage, ropes, cables, textiles",
  "Umbrellas, sun umbrellas, walking-sticks, seat-sticks, whips, riding-crops and parts thereof",
  "Unknown",
  "US Flag - US Agricultural Cargoes",
  "US Flag - US Civilian Agencies Cargo",
  "US Flag - US Export Import Bank",
  "US Flag - US Foreign Military Sales",
  "US Flag - US Military Cargo (other than household goods)",
  "US Government Household Goods and Privately Owned Vehicles",
  "USED - Tires, tyres, rubber",
  "Vanadium oxides, hydroxides",
  "Vegetable juice, frozen, foodstuff",
  "Vegetable juice, non-frozen, foodstuff",
  "Vegetable materials, vegetable products",
  "Vegetable saps, extracts, frozen, vegetables",
  "Vegetable saps, extracts, non-frozen, vegetables",
  "Vegetables",
  "Vegetables, nos, frozen, vegetables",
  "Vegetables, nos, non-frozen, vegetables",
  "Vegetables, preserved, canned, non-frozen, foodstuff",
  "Vegetables, preserved, frozen, foodstuff",
  "Vehicles",
  "Vehicles, cars, buses, trucks, lorries, motorcycles, bicycles, knock-down, CKD, new",
  "Vehicles, cars, buses, trucks, lorries, motorcycles, bicycles, used",
  "Veneer, sliced, peeled",
  "Vinegar, foodstuff",
  "Wadding, felt, nonwovens, thread, cord, yarn, strip, textiles",
  "Walnuts",
  "Wastepaper",
  "Watermelons, frozen, fruit",
  "Watermelons, non-frozen, fruit",
  "Waxes",
  "White goods",
  "White pigments, titanium dioxide",
  "Wine, beverages bottled",
  "Wine, beverages, bulk",
  "Wood",
  "Wood pulp",
  "Wood, articles of wood, nos",
  "Wool, animal hair, textiles",
  "Yams, non-frozen, vegetables",
  "Yeast, foodstuff",
  "Zinc, zinc articles, metal",
]
