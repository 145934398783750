export default {
  TYPE_LIST: [
    {
      value: "",
      label: "Select",
    },
    {
      value: "DRY Standard",
      label: "DRY Standard",
    },
    {
      value: "DRY High",
      label: "DRY High",
    },
    {
      value: "REEF Standard",
      label: "REEF Standard",
    },
    {
      value: "Open Top",
      label: "Open Top",
    },
    {
      value: "REEF High",
      label: "REEF High",
    },
    {
      value: "Flat Standard",
      label: "Flat Standard",
    },
    {
      value: "Open Top High",
      label: "Open Top High",
    },
    {
      value: "TANK",
      label: "TANK",
    },
    {
      value: "Flat High",
      label: "Flat High",
    },
  ],

  SIZE_LIST: [
    {
      value: "",
      label: "Select",
    },
    {
      value: "20",
      label: "20'",
    },
    {
      value: "40",
      label: "40'",
    },
    {
      value: "45",
      label: "45'",
    },
  ],
}
