export default {
  ONE_ROW_DISCOVER: [
    {
      number: "1",
      title: "APM-MAERSK",
      cardClass: "card-st-chpa border-st-chpa card-st-chpa-mb border-st-chpa-mb",
      numberClass: "color-st-chpa color-st-chpa-mb",
      data: {
        url: "https://www.maersk.com/",
        social: [
          {
            url: "https://www.facebook.com/Maersk/",
            name: "Facebook",
            socialClass: "fa-facebook facebook-chpa",
          },
          {
            url: "https://www.instagram.com/maersk_official/",
            name: "Instagram",
            socialClass: "fa-instagram instagram-chpa",
          },
          {
            url: "https://twitter.com/Maersk/",
            name: "Twitter",
            socialClass: "fa-twitter twitter-chpa",
          },
          {
            url: "https://www.linkedin.com/company/maersk-group/",
            name: "Linkedin",
            socialClass: "fa-linkedin linkedin-chpa",
          },
        ],
      },
    },

    {
      number: "2",
      title: "MSC",
      cardClass: "card-st-chpa border-st-chpa card-rd-chpa-mb border-rd-chpa-mb",
      numberClass: "color-st-chpa color-rd-chpa-mb",
      data: {
        url: "https://www.msc.com/",
        social: [
          {
            url: "https://www.facebook.com/MSCCargo/",
            name: "Facebook",
            socialClass: "fa-facebook facebook-chpa",
          },
          {
            url: "https://www.instagram.com/msccargo/",
            name: "Instagram",
            socialClass: "fa-instagram instagram-chpa",
          },
          {
            url: "https://twitter.com/msccargo",
            name: "Twitter",
            socialClass: "fa-twitter twitter-chpa",
          },
          {
            url: "https://www.linkedin.com/company/msc-mediterranean-shipping-co--s-a-/",
            name: "Linkedin",
            socialClass: "fa-linkedin linkedin-chpa",
          },
        ],
      },
    },

    {
      number: "3",
      title: "COSCO",
      cardClass: "card-st-chpa border-st-chpa card-nd-chpa-mb border-nd-chpa-mb",
      numberClass: "color-st-chpa color-nd-chpa-mb",
      data: {
        url: "http://lines.coscoshipping.com/home/",
        social: [
          {
            url: "https://www.facebook.com/coscoshippinglinesjordan/",
            name: "Facebook",
            socialClass: "fa-facebook facebook-chpa",
          },
          {
            url: "https://www.youtube.com/channel/UCvDsI8VUFzcYfkDBIAjXtLw",
            name: "YouTube",
            socialClass: "fa-youtube youtube-chpa",
          },
          {
            url: "https://www.linkedin.com/company/cosco-shipping-lines/about/",
            name: "Linkedin",
            socialClass: "fa-linkedin linkedin-chpa",
          },
        ],
      },
    },
  ],

  TWO_ROW_DISCOVER: [
    {
      number: "4",
      title: "CMA-CGM",
      cardClass: "card-nd-chpa border-nd-chpa card-st-chpa-mb border-st-chpa-mb",
      numberClass: "color-nd-chpa color-st-chpa-mb",
      data: {
        url: "https://www.cma-cgm.com/",
        social: [
          {
            url: "https://www.facebook.com/cmacgm",
            name: "Facebook",
            socialClass: "fa-facebook facebook-chpa",
          },
          {
            url: "https://www.instagram.com/cmacgm/",
            name: "Instagram",
            socialClass: "fa-instagram instagram-chpa",
          },
          {
            url: "https://twitter.com/CMACGM",
            name: "Twitter",
            socialClass: "fa-twitter twitter-chpa",
          },
          {
            url: "https://www.linkedin.com/company/cma-cgm",
            name: "Linkedin",
            socialClass: "fa-linkedin linkedin-chpa",
          },
        ],
      },
    },

    {
      number: "5",
      title: "HAPAG-LLOYD",
      cardClass: "card-nd-chpa border-nd-chpa card-rd-chpa-mb border-rd-chpa-mb",
      numberClass: "color-nd-chpa color-rd-chpa-mb",
      data: {
        url: "https://www.hapag-lloyd.com/en/home.html",
        social: [
          {
            url: "https://www.facebook.com/hapaglloydag/",
            name: "Facebook",
            socialClass: "fa-facebook facebook-chpa",
          },
          {
            url: "https://www.instagram.com/hapaglloydag/",
            name: "Instagram",
            socialClass: "fa-instagram instagram-chpa",
          },
          {
            url: "https://twitter.com/hapaglloydag",
            name: "Twitter",
            socialClass: "fa-twitter twitter-chpa",
          },
          {
            url: "https://www.linkedin.com/company/hapag-lloyd-ag/",
            name: "Linkedin",
            socialClass: "fa-linkedin linkedin-chpa",
          },
        ],
      },
    },

    {
      number: "6",
      title: "ONE OCEAN",
      description: "NETWORK EXPRESS",
      cardClass: "card-nd-chpa border-nd-chpa card-nd-chpa-mb border-nd-chpa-mb",
      numberClass: "color-nd-chpa color-nd-chpa-mb",
      data: {
        url: "https://www.one-line.com/",
        social: [
          {
            url: "https://www.facebook.com/ocean.network.express.global",
            name: "Facebook",
            socialClass: "fa-facebook facebook-chpa",
          },
          {
            url: "https://www.instagram.com/ocean_network_express/?hl=",
            name: "Instagram",
            socialClass: "fa-instagram instagram-chpa",
          },
          {
            url: "https://twitter.com/oceannetworkexp",
            name: "Twitter",
            socialClass: "fa-twitter twitter-chpa",
          },
          {
            url: "https://www.linkedin.com/company/oceannetworkexpress/",
            name: "Linkedin",
            socialClass: "fa-linkedin linkedin-chpa",
          },
        ],
      },
    },

    {
      number: "7",
      title: "EVERGREEN LINE",
      cardClass: "card-nd-chpa border-nd-chpa card-st-chpa-mb border-st-chpa-mb",
      numberClass: "color-nd-chpa color-st-chpa-mb",
      data: {
        url: "https://www.evergreen-line.com/",
        social: [
          {
            url: "https://www.facebook.com/evergreenlinesouthphilippines/",
            name: "Facebook",
            socialClass: "fa-facebook facebook-chpa",
          },
          {
            url: "https://www.instagram.com/evergreen.line/",
            name: "Instagram",
            socialClass: "fa-instagram instagram-chpa",
          },
          {
            url: "https://www.youtube.com/channel/UC0CfeZUHFm7w5A6c4rGhU4Q/featured",
            name: "YouTube",
            socialClass: "fa-youtube youtube-chpa",
          },
          {
            url: "https://www.linkedin.com/company/evergreen-line/about/",
            name: "Linkedin",
            socialClass: "fa-linkedin linkedin-chpa",
          },
        ],
      },
    },
  ],

  THREE_ROW_DISCOVER: [
    {
      number: "8",
      title: "YANG-MING",
      description: "MARINE TRANSPORT",
      cardClass: "card-rd-chpa border-rd-chpa card-rd-chpa-mb border-rd-chpa-mb",
      numberClass: "color-rd-chpa color-rd-chpa-mb",
      data: {
        url: "https://www.yangming.com/",
        social: [
          {
            url: "https://www.facebook.com/yangmingph/",
            name: "Facebook",
            socialClass: "fa-facebook facebook-chpa",
          },
          {
            url: "https://www.linkedin.com/company/yang-ming-marine-corp/",
            name: "Linkedin",
            socialClass: "fa-linkedin linkedin-chpa",
          },
        ],
      },
    },

    {
      number: "9",
      title: "PIL PACIFIC",
      description: "INTERNATIONAL LINE",
      cardClass: "card-rd-chpa border-rd-chpa card-nd-chpa-mb border-nd-chpa-mb",
      numberClass: "color-rd-chpa color-nd-chpa-mb",
      data: {
        url: "https://www.pilship.com/",
        social: [
          {
            url: "https://www.facebook.com/pilsingapore",
            name: "Facebook",
            socialClass: "fa-facebook facebook-chpa",
          },
          {
            url: "https://twitter.com/PilSingapore",
            name: "Twitter",
            socialClass: "fa-twitter twitter-chpa",
          },
          {
            url: "https://www.linkedin.com/company/pacific-international-lines-pte-ltd/",
            name: "Linkedin",
            socialClass: "fa-linkedin linkedin-chpa",
          },
        ],
      },
    },

    {
      number: "10",
      title: "HYUNDAI",
      description: "MERCHANT MARINE",
      cardClass: "card-rd-chpa border-rd-chpa card-st-chpa-mb border-st-chpa-mb",
      numberClass: "color-rd-chpa color-st-chpa-mb",
      data: {
        url: "http://www.hmm21.com/",
        social: [
          {
            url: "https://www.facebook.com/hmm21.official/",
            name: "Facebook",
            socialClass: "fa-facebook facebook-chpa",
          },
          {
            url: "https://www.instagram.com/hmm21.official/",
            name: "Instagram",
            socialClass: "fa-instagram instagram-chpa",
          },
          {
            url: "https://www.youtube.com/hmmofficial1976",
            name: "Youtube",
            socialClass: "fa-youtube youtube-chpa",
          },
          {
            url: "https://www.linkedin.com/company/hyundai-merchant-marine/",
            name: "Linkedin",
            socialClass: "fa-linkedin linkedin-chpa",
          },
        ],
      },
    },
  ],
}
