import React from "react"
import { Link } from "gatsby"

const ItemLang = () => {
  return (
    <li className="nav-item dropdown">
      <Link
        className="nav-link dropdown-toggle navlink-chpa"
        to="#"
        id="language-chpa"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        ENG
      </Link>
      <div className="dropdown-menu dropdown-menu-right" aria-labelledby="language-chpa">
        <Link className="dropdown-item" to="#">
          ES
        </Link>
      </div>
    </li>
  )
}

export default ItemLang
