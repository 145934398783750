import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"

const Item = ({ children, to }) => {
  return (
    <li className="nav-item">
      <Link className="nav-link navlink-chpa" to={to}>
        {children}
      </Link>
    </li>
  )
}

Item.prototypes = {
  to: PropTypes.string.isRequired,
}

export default Item
