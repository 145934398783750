import React, { useContext } from "react"
import PropTypes from "prop-types"

// Context
import NavBarItemContext from "../../context/navBarItemContext"

const ItemMobile = ({ children, to }) => {
  const { setShowMenuNavMobile } = useContext(NavBarItemContext)

  const handleClick = () => {
    setShowMenuNavMobile(false)
  }

  return (
    <a className="options-section-chpa" href={to} onClick={handleClick}>
      {children}
    </a>
  )
}

ItemMobile.prototypes = {
  to: PropTypes.string.isRequired,
}

export default ItemMobile
