export default {
  MIN_VIEW_SOLID_NAVBAR: 200,
  ITEM_NAVBAR: [
    {
      to: "#whoWeAreWho",
      name: "Who We Are",
    },
    {
      to: "#contactUs",
      name: "Contact Us",
    },
  ],
  ITEM_ICON_NAVBAR: [
    {
      url: "https://www.facebook.com/",
      socialClass: "fa-facebook-square",
    },
    {
      url: "https://www.instagram.com/chalupaship/",
      socialClass: "fa-instagram",
    },
  ],
}
