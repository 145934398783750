import React from "react"
import PropTypes from "prop-types"

const ItemIcon = ({ url, socialClass }) => {
  return (
    <li className="nav-item">
      <a className="nav-link navlink-chpa" target="_blank" href={url} rel="noreferrer noopener">
        <i className={`fa ${socialClass} navbar-social-chpa`}></i>
      </a>
    </li>
  )
}

ItemIcon.prototypes = {
  url: PropTypes.string.isRequired,
  socialClass: PropTypes.string.isRequired,
}

export default ItemIcon
