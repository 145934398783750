import React, { useEffect } from "react"
import AOS from "aos"

// Components
import Navbar from "./Navbar"

// Import CSS
import "bootstrap/dist/css/bootstrap.min.css"
import "animate.css/animate.css"
import "aos/dist/aos.css"
import "./style.css"
import "./util.css"

// fonts
import "../fonts/ionicons/css/ionicons.css"
import "../fonts/fontawesome/css/font-awesome.min.css"
import "../fonts/flag-icon/flag-icon.css"

const Layout = ({ children }) => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
    })

    return () => {
      AOS.refresh()
    }
  }, [])

  return (
    <>
      <Navbar />
      {children}
    </>
  )
}

export default Layout
