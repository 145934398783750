import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import classNames from "classnames"
import logo from "../../images/LogoChalupaNo2.png"

// Components
import Item from "./Item"
import ItemLang from "./ItemLang"
import ItemIcon from "./ItemIcon"
import ItemMobile from "./ItemMobile"
import ItemIconMobile from "./ItemIconMobile"

// Context
import NavBarItemContext from "../../context/navBarItemContext"

// Custom Hook
import useScrollPosition from "../../hook/useScrollPositions"

// Constants
import { navBar as constants } from "../../constants" // Constants
import { HIDE_LANG_COMPONENT, HIDE_SOCIAL_COMPONENT } from "../../constants/global"

const Navbar = () => {
  const { MIN_VIEW_SOLID_NAVBAR, ITEM_NAVBAR, ITEM_ICON_NAVBAR } = constants

  const [isShowMenuNavMobile, setShowMenuNavMobile] = useState(false)
  const [scroll, setScroll] = useState(0)

  useScrollPosition(({ currentPosition }) => {
    setScroll(currentPosition.y)
  })

  useEffect(() => {
    document.getElementById("myNav").style.width = isShowMenuNavMobile ? "100%" : "0%"
  }, [isShowMenuNavMobile])

  return (
    <nav
      className={classNames(
        "navbar",
        "navbar-expand-lg",
        "fixed-top",
        "navbar-chpa",
        "p-3",
        "md-5",
        "mb-3",
        scroll > MIN_VIEW_SOLID_NAVBAR ? "solid" : ""
      )}
      id="chalupa-navbar"
    >
      <div className="container">
        <div className="navbar-brand-chpa">
          <img
            className="logo-img-chpa"
            src={logo}
            alt="Logo Chalupa"
            style={{
              display: scroll > MIN_VIEW_SOLID_NAVBAR ? "inline" : "none",
            }}
          />
        </div>
        <button
          className="navbar-toggler navbar-toggler-right"
          onClick={() => setShowMenuNavMobile(true)}
          type="button"
          data-target="#navbarResponsiveChpa"
          aria-controls="navbarResponsiveChpa"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <i className="fa fa-bars"></i>
        </button>
        <div className="collapse navbar-collapse" id="navbarResponsiveChpa">
          <ul className="navbar-nav ml-auto">
            {ITEM_NAVBAR.map(({ to, name }, index) => (
              <Item key={index} to={to}>
                {name}
              </Item>
            ))}

            {HIDE_LANG_COMPONENT === false ? <ItemLang /> : ""}

            {HIDE_SOCIAL_COMPONENT === false
              ? ITEM_ICON_NAVBAR.map(({ url, socialClass }, index) => <ItemIcon key={index} url={url} socialClass={socialClass} />)
              : ""}
          </ul>
        </div>

        {/* Navbar Chalupa Mobil */}
        <NavBarItemContext.Provider
          value={{
            setShowMenuNavMobile,
          }}
        >
          <div id="myNav" className="overlay-chpa">
            <Link
              to="#"
              className="closebtn-chpa"
              onClick={(e) => {
                setShowMenuNavMobile(false)
                e.preventDefault()
              }}
            >
              &times;
            </Link>
            <div className="overlay-content-chpa">
              {ITEM_NAVBAR.map(({ to, name }, index) => (
                <ItemMobile key={index} to={to}>
                  {name}
                </ItemMobile>
              ))}

              {HIDE_SOCIAL_COMPONENT === false
                ? ITEM_ICON_NAVBAR.map(({ url, socialClass }, index) => <ItemIconMobile key={index} url={url} socialClass={socialClass} />)
                : ""}
            </div>
          </div>
        </NavBarItemContext.Provider>
      </div>
    </nav>
  )
}

export default Navbar
